import { observer } from "mobx-react";
import * as React from "react";
import stores from "../../stores";

type Props = {
  rejectionContent: React.ReactNode;
  children: JSX.Element | string;
};

type StoreProps = {
  isLoggedIn: boolean;
};

/**
 * @example
 * // Redirects to index if user is not logged in.
 * <RequiresAuthentication rejectionContent={<Redirect to="/" />} />
 *
 * @example
 * // Displays error if user is not logged in.
 * <RequiresAuthentication rejectionContent={<p>You must be logged in to view this.</p>} />
 */
export default observer((props: Props) => <RequiresAuthentication {...props} isLoggedIn={
  
  stores.session.isLoggedIn} />);

export const RequiresAuthentication: React.FC<Props & StoreProps> = (props) => (
  <div>{props.isLoggedIn ? props.children : props.rejectionContent}</div>
);
