import ApiClient from "../services/apiClient";
import RunVinClient from "../services/runVinClient";
import { Environment, getApplicationRootUrl, getEnvironment } from "../utils/environment";

function getApiClient() {
  console.log("geeEnvironment ", getEnvironment())
  return getEnvironment() === Environment.LOCAL ? `https://stagingemployees.carfax.net/api` : getApplicationRootUrl() + `/api`;

}
export const employeePortalApiClient = new ApiClient(getApiClient())

export const runVinClient = new RunVinClient(employeePortalApiClient);
